// Copyright 2023-2024 - Hewlett Packard Enterprise Company
// The function below escapes regular expression special characters:
// [ \ ^ $ . | ? * + ( )
export const escapedText = text => text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

export function filterData(searchObject, data) {
  let nextData;
  if (searchObject) {
    const expressions = Object.keys(searchObject).map(property => ({
      property,
      // Create the regular expression with modified value which handles
      // escaping special characters. Without escaping special characters,
      // errors will appear in the console
      exp: new RegExp(escapedText(searchObject[property]), 'i'),
    }));
    nextData = data.filter(
      d => !expressions.some(e => !e.exp.test(d[e.property])),
    );
  } else {
    nextData = data;
  }
  return nextData;
}

export const convertSizeFromMiBtoGBorTB = capacityMiB => {
  const sizeInGB = parseInt(0.00104858 * Number(capacityMiB), 10);
  const size = sizeInGB >= 1000 ? `${sizeInGB / 1000} TB` : `${sizeInGB} GB`;
  return size;
};

export const convertSizeFromBytesToGBorTB = capacityBytes => {
  const sizeInGB = parseInt(0.000000001 * Number(capacityBytes), 10);
  const size = sizeInGB >= 1000 ? `${sizeInGB / 1000} TB` : `${sizeInGB} GB`;
  return size;
};

export const convertSizeFromMiBtoGiB = capacityMiB =>
  Math.round(Number(capacityMiB / 1024) * 10) / 10;

export const convertSizeFromBytestoGiB = capacityBytes =>
  parseInt(Math.round(capacityBytes / 1073741824), 10);

export const convertSizeFromGBToBytes = capacityGB => capacityGB * 1000000000;

export const convertSizeFromBytestoGiBWithTwoDecimal = capacityBytes =>
  (capacityBytes / 1073741824).toFixed(2);

export const convertSizeFromBytesToMiB = capacityBytes =>
  parseInt(Math.round(capacityBytes / 1049000), 10);

export const formatLangStr = (format, data) => {
  let result = format;
  data.forEach((val, idx) => {
    result = result.replace(`{${idx}}`, val);
  });
  return result;
};
